<template>
  <div class="container">
    <div class="d-flex align-items-center page-header">
      <div class="pre-divider"></div>
      <h2>Confirm setup</h2>
      <div class="pre-divider"></div>
    </div>
    <div class="text-center mb-5">
      <img
        src="/assets/images/icons/auto-wave.svg"
        alt="headphones"
        class="mr-3"
      />
      Best Experienced with headphones
    </div>
    <div class="d-flex w-100 align-items-center mb-4">
      <img src="/assets/images/icons/mic.svg" alt="mic" class="mr-5" />
      <b-form-group label="Microphone" class="mb-0 flex-grow-1">
        <pnw-select
          :options="audioInputs"
          v-model="form.mic"
          placeholder="Select one"
          :clearable="false"
        />
      </b-form-group>
    </div>
    <div class="d-flex w-100 align-items-center mb-5">
      <img src="/assets/images/icons/camera.png" alt="camera" class="mr-5" />
      <b-form-group label="Camera" class="mb-0 flex-grow-1">
        <pnw-select
          :options="videoInputs"
          v-model="form.camera"
          placeholder="Select one"
          :clearable="false"
        />
      </b-form-group>
    </div>
    <div class="text-center pt-5">
      <pnw-button
        :disabled="!form.mic || !form.camera"
        variant="primary"
        size="xl"
        >Enter Class</pnw-button
      >
    </div>
  </div>
</template>
<script>
import PnwButton from "../components/PnwButton.vue";
import PnwSelect from "../components/PnwSelect.vue";
export default {
  components: { PnwButton, PnwSelect },
  name: "AVCheck",
  data() {
    return {
      form: {
        mic: "",
        camera: "",
      },
      audioInputs: [],
      videoInputs: [],
    };
  },
  methods: {
    async checkDevices() {
      try {
        var md = navigator.mediaDevices;
        if (!md || !md.enumerateDevices) return;

        const devices = await md.enumerateDevices();
        devices.forEach((device) => {
          switch (device.kind) {
            case "audioinput":
              this.audioInputs.push(
                device.label || "Microphone " + (this.audioInputs.length + 1)
              );
              break;
            case "audiooutput":
              break;
            case "videoinput":
              this.videoInputs.push(
                device.label || "Camera " + (this.videoInputs.length + 1)
              );
              break;
          }
        });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },
  mounted() {
    this.checkDevices();
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 600px;
}
.page-header {
  margin-bottom: 64px;
  h2 {
    margin: 0 32px;
  }
  .pre-divider {
    flex-grow: 1;
    margin-bottom: 0;
  }
}
</style>
