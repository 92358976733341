<template>
  <div @click="clickEvent">
    <v-select
      :options="options"
      v-model="valueCopy"
      :placeholder="placeholder"
      :clearable="clearable"
      :multiple="multiple"
    />
  </div>
</template>
<script>
import audioLevel from "../constants/audioConstants";
import playSound from "../services/AudioService";
export default {
  name: "PnwSelect",
  props: {
    value: {
      required: true,
    },
    placeholder: {
      default: "",
    },
    clearable: {
      default: false,
    },
    options: {
      default: [],
    },
    multiple: {
      default: false,
    },
  },
  methods: {
    clickEvent() {
      playSound("GF-PN1-DropDown.wav");
    },
  },
  computed: {
    valueCopy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("changed", value);
      },
    },
  },
};
</script>
